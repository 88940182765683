import React, { Component } from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Layout from "../components/layout";
import Lightbox from "react-images";
import MarkdownRenderer from "react-markdown-renderer";

class SingleRealization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
      lang: this.props.pageContext.lang,
      data: this.props.pageContext.data,
      breadcrumbs: this.props.pageContext.breadcrumbs,
      menu: this.props.pageContext.menu,
      footerMenu: this.props.pageContext.footerMenu,
      lightboxTitle: this.props.pageContext.data.title,
    };
  }

  handleLightBoxOpen = (currentImage) => {
    this.setButtonsHeight();
    this.setState({ currentImage, lightboxIsOpen: true });
    setTimeout(() => {
      const header = document.querySelector(
        '#lightboxBackdrop [class^="header"] span'
      );
      if (!header) return false;
      header.innerHTML = this.state.lightboxTitle;
    }, 100);
  };

  gotoNext = (currentImage) => {
    this.setButtonsHeight();
    this.setState({
      currentImage: currentImage + 1,
    });
  };
  gotoPrevious = (currentImage) => {
    this.setButtonsHeight();
    this.setState({
      currentImage: currentImage - 1,
    });
  };
  closeLightbox = () =>
    this.setState({ lightboxIsOpen: false });

  setButtonsHeight = () => {
    setTimeout(() => {
      const buttons = document.querySelectorAll(
        '[class^="arrow"]'
      );
      const images = document.querySelectorAll(
        '[class*="imageLoaded"]'
      );
      if (images && images.length > 0) {
        const height = images[0].height;
        buttons.map = Array.prototype.map;
        buttons.map((element) => {
          element.style.height = `${height}px`;
          element.style.opacity = `1`;
          return element;
        });
      }
    }, 300);
  };

  render() {
    const {
      breadcrumbs,
      currentImage,
      lightboxIsOpen,
      lang,
      menu,
      footerMenu,
      data: {
        hrefLang,
        title,
        short_description,
        realizations_list,
        place,
        video,
      },
    } = this.state;

    const main_image = this.state.data.images;
    const images = this.state.data.gallery_realization
      ? this.state.data.gallery_realization
          .filter((item) => item && !!item.image)
          .map(({ image, title }) => ({
            src: image,
            caption: title,
          }))
      : [];

    return (
      <Layout
        menu={menu}
        footerMenu={footerMenu}
        title={`${title} - Posadzki Przemysłowe Polska`}
      >
        {images && (
          <Lightbox
            images={
              main_image
                ? [
                    { src: main_image, caption: title },
                    ...images,
                  ]
                : images
            }
            isOpen={lightboxIsOpen}
            onClickPrev={() =>
              this.gotoPrevious(currentImage, images)
            }
            onClickNext={() =>
              this.gotoNext(currentImage, images)
            }
            showCloseButton={true}
            onClose={this.closeLightbox}
            currentImage={currentImage}
            backdropClosesModal={true}
            imageCountSeparator={"/"}
          />
        )}
        <div className="bg-gradient">
          <Header lang={lang} hrefLang={hrefLang} />
          <Breadcrumbs data={breadcrumbs} lang={lang} />
          <div className="main-wrapper-md">
            <section className="single-realization-header">
              <header className="section-header red-line">
                <h1>
                  <strong>{title}</strong>
                </h1>
              </header>
              <h2 className="single-realization-location">
                {place}
              </h2>
              <div className="single-realization-info">
                <div className="text-holder">
                  {short_description && (
                    <p>{short_description}</p>
                  )}
                  <div className="realization-info-list">
                    <h3 className="red-line line-md">
                      Wykonane <strong>prace</strong>
                    </h3>
                    <MarkdownRenderer
                      markdown={realizations_list}
                    />
                  </div>
                </div>
                {main_image && (
                  <div className="image-holder">
                    <img
                      src={main_image}
                      alt=""
                      onClick={() =>
                        this.handleLightBoxOpen(0)
                      }
                    />
                    <button
                      className="btn-zoom"
                      onClick={() =>
                        this.handleLightBoxOpen(0)
                      }
                      type="button"
                    >
                      <img
                        src={require("../images/zoom-icon.svg")}
                        alt=""
                      />
                    </button>
                  </div>
                )}
              </div>
            </section>
            {video?.length > 0 && (
              <div className="video-container">
                <video width="100%" controls>
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            )}
          </div>
        </div>
        {images?.length > 0 && (
          <div className="bg-gradient">
            <div className="main-wrapper-md">
              <section className="realizations-gallery">
                {images.map(({ src }, index) => (
                  <div
                    key={index}
                    className="gallery-item"
                    style={{
                      backgroundImage: src
                        ? `url(${encodeURI(src)})`
                        : "",
                    }}
                  >
                    <div
                      className="overlay"
                      onClick={() =>
                        this.handleLightBoxOpen(
                          main_image ? index + 1 : index
                        )
                      }
                    />
                    <button
                      className="btn-zoom"
                      onClick={() =>
                        this.handleLightBoxOpen(index)
                      }
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.207"
                        height="31.207"
                        viewBox="0 0 31.207 31.207"
                      >
                        <g
                          id="Group_284"
                          data-name="Group 284"
                          transform="translate(-244.5 -424.5)"
                        >
                          <rect
                            id="Rectangle_1257"
                            data-name="Rectangle 1257"
                            width="4.243"
                            height="9.899"
                            rx="1"
                            transform="translate(265 448) rotate(-45)"
                            fill="none"
                            stroke="#db0316"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="1"
                          />
                          <circle
                            id="Ellipse_34"
                            data-name="Ellipse 34"
                            cx="11.5"
                            cy="11.5"
                            r="11.5"
                            transform="translate(245 425)"
                            fill="none"
                            stroke="#db0316"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="1"
                          />
                          <circle
                            id="Ellipse_35"
                            data-name="Ellipse 35"
                            cx="9.5"
                            cy="9.5"
                            r="9.5"
                            transform="translate(247 427)"
                            fill="none"
                            stroke="#db0316"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_466"
                            data-name="Path 466"
                            d="M267,446l-1.5-1.5a.707.707,0,0,0-1,0h0a.707.707,0,0,0,0,1L266,447"
                            fill="none"
                            stroke="#db0316"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                ))}
              </section>
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

export default SingleRealization;
